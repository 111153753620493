import axios from "axios";

const addQuestion = (data) => {
     let url = `${process.env.REACT_APP_API_SERVER_HOST }/faq/add`;
    return axios.post(url, data,{ headers: { 'pubid': localStorage.getItem('pub_id'),'channelid':localStorage.getItem("currentChannel") ,role_id:localStorage.getItem("role_id") } }).then(response => {
        return response.data
    }).catch(e => { return { data: false } })
  }

  const updateQuestion = (data) => {
    let url = `${process.env.REACT_APP_API_SERVER_HOST }/faq/update`;
   return axios.post(url, data,{ headers: { 'pubid': localStorage.getItem('pub_id'),'channelid':localStorage.getItem("currentChannel")  } }).then(response => {
       return response.data
   }).catch(e => { return { data: false } })
 }
  const listQuestion = (path) => {
     let url = `${process.env.REACT_APP_API_SERVER_HOST }/faq/list`;
    return axios.get(url, {
      headers: { 
        'pubid': localStorage.getItem('pub_id'),
        'channelid': localStorage.getItem("currentChannel")
      },
      params: {
        path: path
      }
    }).then(response => {
      return response.data;
    }).catch(e => {
      return { data: false };
    });
  };
  const deleteQuestion = (id) => {
    let url = `${process.env.REACT_APP_API_SERVER_HOST }/faq/delete/${id}`;
   return axios.post(url,null,{ headers: { 'pubid': localStorage.getItem('pub_id'),'channelid':localStorage.getItem("currentChannel")  } }).then(response => {
       return response.data
   }).catch(e => { return { data: false } })
 }

  const getQuestion = (vanity_url) => {
    let url =  `${process.env.REACT_APP_API_SERVER_HOST }/faq/${vanity_url}`;
    return axios.get(url, {
      headers: { 
        'pubid': localStorage.getItem('pub_id'),
        'channelid': localStorage.getItem("currentChannel")
      }
    }).then(response => {
      return response.data;
    }).catch(e => {
      return { data: false };
    });
  };
  const searchQuestion = (search) => {
    let url =  `${process.env.REACT_APP_API_SERVER_HOST }/faq/search/${search}`;
    return axios.get(url, {
      headers: { 
        'pubid': localStorage.getItem('pub_id'),
        'channelid': localStorage.getItem("currentChannel")
      }
    }).then(response => {
      return response.data;
    }).catch(e => {
      return { data: false };
    });
  };

export const service = {
    addQuestion,
    listQuestion,
    getQuestion,
    searchQuestion,
    updateQuestion,
    deleteQuestion
   }
