import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import team3 from '../../assets/img/team/avatar.png';
import Avatar from '../common/Avatar';
import { toast } from 'react-toastify';

const ProfileDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  if(localStorage.getItem("channelConfig") && typeof localStorage.getItem("channelConfig") !== 'undefined' && (localStorage.getItem("channelConfig") !=='undefined') && localStorage.getItem("channelConfig")!=="[]"){
  var publisherChannels = JSON.parse(localStorage.getItem("channelConfig"));
  }else{
    var publisherChannels=[]
  }
 var forgott_style
  if(window.location.pathname == '/ForgotPassword'){
  forgott_style = 'none'
    }
  publisherChannels &&
    localStorage.setItem(
      "currentChannel",
      localStorage.getItem("currentChannel")
        ? localStorage.getItem("currentChannel")
        : publisherChannels?.[0]?.channel_id
    );

  const channelChange = (value) => {
    localStorage.setItem('currentChannel', document.getElementById('commonChannel').value)
    setTimeout(() => {
    if(window.location.href != "/"){
      window.location.href = "/"
    }
    }, 100);
  }
  const revertToSuperAdmin = () => {
    let superadmin = JSON.parse(localStorage.getItem("superadmin"));
    let {
      role_id,
      isRTL,
      user_name,
      channelConfig,
      favouriteItems,
      publisherConfig,
      bucketType,
      isFluid,
      currentChannel,
      isTopNav,
      pub_id,
      isDark,
      isNavbarVerticalCollapsed,
      user_email,
      products,
      shoppingCart
    } = superadmin
    localStorage.setItem("role_id", role_id)
    localStorage.setItem("isRTL", isRTL)
    localStorage.setItem("user_name", user_name)
    localStorage.setItem("channelConfig", channelConfig)
    localStorage.setItem("favouriteItems", favouriteItems)
    localStorage.setItem("publisherConfig", publisherConfig)
    localStorage.setItem("bucketType", bucketType)
    localStorage.setItem("isFluid", isFluid)
    localStorage.setItem("currentChannel", currentChannel)
    localStorage.setItem("isTopNav", isTopNav)
    localStorage.setItem("pub_id", pub_id)
    localStorage.setItem("isDark", isDark)
    localStorage.setItem("isNavbarVerticalCollapsed", isNavbarVerticalCollapsed)
    localStorage.setItem("user_email", user_email)
    localStorage.setItem("products", products)
    localStorage.getItem("shoppingCart", shoppingCart)
    localStorage.removeItem("superadmin");
    localStorage.removeItem("influencer_id");
    localStorage.removeItem("merchant_id");
    localStorage.removeItem("merchant_name");
    localStorage.setItem("add_server_admin", "false");
    toast.success(`Reverting to Superadmin`);
    window.location.href = '/'
  }
  const clearLocalStorage =()=>{
    Object.keys(localStorage).forEach((key) => {
      // key !== "superadmin_mfa_verified" &&
      if ( key !== "existingPublishers") {
        localStorage.removeItem(key);
      }
    });
    window.location.href = "/login";
}
  const resetPassword =()=>{
    window.location.href = "/password/reset";
}
const dataUsageAndLimits =()=>{
  window.location.href = "/usage/limits";
}
const getPublisherConfigInfo = keyName => {
  let response = false;
  if(localStorage.getItem("publisherConfig") && (typeof localStorage.getItem("publisherConfig") !== 'undefined' && (localStorage.getItem("publisherConfig") !=='undefined'))){
  var publisherConfig = JSON.parse(localStorage.getItem("publisherConfig"));
  if (publisherConfig &&  publisherConfig?.length>0) {
    let configInfo = publisherConfig.filter(i => keyName == i.key_name);
    response = configInfo[0] && configInfo[0].key_value;
    return response;
  }
  }
  return response;
};
  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(false);
      }}
      style={{display:forgott_style}}
    >
      <DropdownToggle nav className="pr-0">
        <Avatar src={team3} />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="">
          <DropdownItem className="font-weight-bold" href="#!">
            <FontAwesomeIcon icon="user" className="mr-1" />
            {(localStorage.getItem('role_id') == 7) ? <span>Super Admin</span> :localStorage.getItem('role_id') == 12 ? <span>{localStorage.getItem('user_email')}</span>:<span>{localStorage.getItem('user_name')}</span>}
          </DropdownItem>
          <DropdownItem divider />
          
          { publisherChannels && publisherChannels.length > 1 && publisherChannels!=="[]" && <select id="commonChannel" className='channel-drop-down' onChange={(value) => { channelChange(value) }} defaultValue={localStorage.getItem('currentChannel')}>
            {publisherChannels && publisherChannels!=="[]" && publisherChannels.map(item => <option value={item.channel_id} key={item.channel_id} >{item.channel_name}</option>)}
          </select>}
          {localStorage.getItem("role_id")==13 &&
          <DropdownItem tag={Link} to="/resetPassword">
            Reset password
          </DropdownItem>
          }
          {
            (localStorage.getItem('role_id') != 7) && getPublisherConfigInfo('data_usage_report_needed') === 'True' &&
            <DropdownItem tag={Link} onClick={dataUsageAndLimits}>
            Data Usage and Limits
          </DropdownItem>
          }
            
          {localStorage.getItem("superadmin") && <DropdownItem onClick={() => {revertToSuperAdmin()}}>Revert to Superadmin</DropdownItem>}
          <DropdownItem tag={Link} onClick={clearLocalStorage}>
            Logout
          </DropdownItem>
{
  !localStorage.getItem("superadmin") ?  <DropdownItem tag={Link} onClick={resetPassword}>
  Reset Password
</DropdownItem>:null
}

        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
